<template>

<div>

  <note-entry :noteSection="1" />

  <notes :noteSection="1" />

</div>

</template>

<script>
import Notes from '@/components/Notes';
import NoteEntry from '@/components/NoteEntry';

export default {
  name: 'NotesList',
  components: {
    Notes,
    NoteEntry,
  },
};
</script>

<style scoped>

</style>
